<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo d-flex align-items-center">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">OptiTmr</h2>
        </b-link>

        <b-card-title class="mb-1"> </b-card-title>
        <b-card-text class="mb-2"> </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2">
            <!-- companyname -->
            <b-form-group
              :label="$t('companyName')"
              label-for="register-companyname"
            >
              <validation-provider
                #default="{ errors }"
                name="Company Name"
                rules="required"
              >
                <b-form-input
                  id="register-companyname"
                  v-model="companyname"
                  name="register-companyname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="iliksoft"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- email -->
            <b-form-group :label="$t('email')" label-for="register-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="info@iliksoft.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- name -->
            <b-form-group :label="$t('name')" label-for="register-name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="register-name"
                  v-model="name"
                  name="register-name"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- lastname -->
            <b-form-group
              :label="$t('lastName')"
              label-for="register-last-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  id="register-last-name"
                  v-model="lastname"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- mobile -->
            <b-form-group :label="$t('mobile')" label-for="register-mobile">
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-form-input
                  id="register-mıbike"
                  v-model="mobile"
                  v-mask="'+############'"
                  :state="errors.length > 0 ? false : null"
                  placeholder="+905555555555"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group label-for="register-password" :label="$t('password')">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              variant="primary"
              block
              type="submit"
              @click.prevent="validationForm"
            >
              {{ $t("register") }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2 w-100">
          <b-button variant="danger" class="w-100" :to="{ name: 'auth-login' }">
            <span> {{ $t("login") }} </span>
          </b-button>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text"></div>
        </div>

        <!-- social buttons -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button variant="facebook" href="javascript:void(0)">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button variant="twitter" href="javascript:void(0)">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button variant="google" href="javascript:void(0)">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button variant="github" href="javascript:void(0)">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BCardTitle,
  BCardText,
  BCard,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BForm,
    BCard,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      companyname: "",
      name: "",
      lastname: "",
      userEmail: "",
      password: "",
      mobile: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(async (success) => {
        if (success) {
          var response = await this.$http.post("Companies/AddCompany", {
            email: this.userEmail,
            password: this.password,
            companyname: this.companyname,
            name: this.name,
            surname: this.lastname,
            phone: this.mobile,
            mobile: this.mobile,
            address: "",
            city: "",
            country: "",
            vd: "",
            vn: "",
          });
          if (response.data.statusdata == "-2")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("companyAlreadyAdded"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          if (response.data.statusdata == "-1")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("userAlreadyAdded"),
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          else
            this.$router.replace(getHomeRouteForLoggedInUser()).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("userCreated"),
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
